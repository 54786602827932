@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Poppins:ital,wght@0,200;0,300;0,500;0,600;1,500&family=Roboto:ital,wght@0,100;0,300;0,400;1,300;1,400&display=swap');
/* font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif; 
font-family: 'Montserrat', sans-serif;*/

@tailwind base;
@tailwind components;
@tailwind utilities;

p{
    color: #000000af;
}

::-webkit-scrollbar{
    width:5px;
}

::-webkit-scrollbar-track{
    background-color:white;
    border-radius: 30px;
}

::-webkit-scrollbar-thumb{
    background-color: rgb(0, 0, 0);
    border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover{
    background-color: rgb(46, 46, 46);
}